<template>
  <div class="env-list">
    <aside class="env-list__head">
      <el-button
        icon="el-icon-plus"
        type="primary"
        @click="handleCreate"
        style="width: 90px"
        v-if="getShowBool('addEnv')"
      >
        新建
      </el-button>
    </aside>
    <list-wrap
      :in-project="false"
      :loading="loading"
      :empty="list.length === 0"
      :handleCreate="handleCreate"
      :hasCreateAuth="getShowBool('add')"
    >
      <env-items :list="list" :env="env" @deleted="handledDeleted" />
    </list-wrap>
    <create-form-dialog ref="createDialogRef" :env="env" @success="fetchList" />
  </div>
</template>
<script>
import { computed, onBeforeUnmount, ref, watch } from 'vue';
import { getShowBool } from '@/utils/permission-show-module';
import { userInfo } from '@/layout/messageCenter/user-info';
import CreateFormDialog from './create/Index.vue';
import EnvItems from './List.vue';
import { getEnvList } from '@/api/project';
import { removeEnvStorage } from '@/utils/env-storage';
import { useRoute, useRouter } from 'vue-router';
import { ENV_ENUM } from '../type';
import { useSaList } from '@/shared/hooks/list';
export default {
  components: {
    CreateFormDialog,
    EnvItems,
  },
  setup() {
    removeEnvStorage();
    const route = useRoute();
    const router = useRouter();
    const createDialogRef = ref();
    const fetchTimer = ref();
    const env = ref(ENV_ENUM.TEST);
    const isProd = computed(() => env.value === ENV_ENUM.PROD);
    const { list, loading, fetchList } = useSaList(async (query, useLoading = true) => {
      clearTimeout(fetchTimer.value);
      if (useLoading) {
        loading.value = true;
      }
      try {
        const { data } = await getEnvList({
          env: env.value,
        });
        list.value = data;
        if (isProd.value) {
          if (list.value.length === 0) {
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            handleCreate();
          } else {
            router.replace(`/env/prod/${list.value[0].id}`);
          }
        }
      } catch (e) {
        console.log(e);
      }
      loading.value = false;
      if (isProd.value) {
        return;
      }
      fetchTimer.value = setTimeout(() => {
        fetchList(false);
      }, 5000);
    });

    const updateEnv = () => {
      env.value = route.name === 'EnvProdList' ? ENV_ENUM.PROD : ENV_ENUM.TEST;
    };
    updateEnv();
    watch(
      () => route.name,
      () => {
        if (route.name === 'EnvProdList' || route.name === 'EnvTestList') {
          updateEnv();
          loading.value = true;
          list.value = [];
          clearTimeout(fetchTimer.value);
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          fetchList();
        }
      },
    );

    fetchList();

    const handleCreate = () => {
      createDialogRef.value.openDialog();
    };

    const handledDeleted = () => {
      fetchList();
    };

    onBeforeUnmount(() => {
      clearTimeout(fetchTimer.value);
    });

    return {
      env,
      list,
      loading,
      userInfo,
      createDialogRef,
      handleCreate,
      getShowBool,
      fetchList,
      handledDeleted,
    };
  },
};
</script>
<style lang="scss" scoped>
.env-list {
  &__head {
    margin-bottom: 20px;
  }
}
</style>
