<template>
  <div class="env-list__wrap">
    <ul class="env-items">
      <li class="env-item" v-for="item in list" :key="item.id" @click="handleGoDetail(item.id)">
        <h3 class="env-item__title">
          <span>{{ item.description || 'Name Placeholder' }}</span>
          <div class="env-item__title_del">
            <template v-if="item.delState > 0">
              <span v-if="item.delState === 1" class="status-text sa-text-progress">删除中</span>
              <span v-if="item.delState === 3" class="status-text sa-text-error">删除异常</span>
            </template>
            <el-tooltip
              content="删除环境"
              placement="top"
              effect="light"
              v-if="getShowBool('deleteEnv') && item.delState !== 1 && env !== 'PROD'"
            >
              <span class="del-icon" @click.stop="handleDelete(item.id)">
                <i class="el-icon-close application-operation__close"></i>
              </span>
            </el-tooltip>
          </div>
        </h3>
        <div class="env-item__desc">
          <span class="env-item__desc_label">英文名</span>
          <span class="env-item__desc_value">{{ item.name }}</span>
        </div>
        <div class="env-item__desc">
          <span class="env-item__desc_label">负责人</span>
          <span class="env-item__desc_value">{{ (item.ownerUsers || []).map((u) => u.displayName).join(',') }}</span>
        </div>
        <div class="env-item__desc">
          <span class="env-item__desc_label">描述</span>
          <span class="env-item__desc_value">{{ item.remark }}</span>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import router from '@/router';
import { getShowBool } from '@/utils/permission-show-module';
import { ElMessage, ElMessageBox } from 'element-plus';
import { deleteEnv } from '@/api/project/project';
import { ENV_ENUM } from '../type';
export default {
  name: 'EnvItems',
  props: {
    env: {
      type: String,
      default: ENV_ENUM.TEST,
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['deleted'],
  setup(props, { emit }) {
    const handleGoDetail = (id) => {
      router.push(`/env/${props.env.toLocaleLowerCase()}/${id}`);
    };
    const handleDelete = (id) => {
      ElMessageBox.confirm(`确定删除当前环境吗？`, '提示', {
        confirmButtonText: '确定删除',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        try {
          await deleteEnv({
            id,
          });
          ElMessage.success('删除命令已下发');
          emit('deleted');
        } catch (e) {}
      });
    };
    return {
      handleGoDetail,
      getShowBool,
      handleDelete,
    };
  },
};
</script>
<style lang="scss" scoped>
.env-list__wrap {
  .env-items {
    display: grid;
    grid-template-columns: repeat(auto-fill, 260px);
    grid-gap: 20px;
    margin: 0;
    padding: 20px;
    list-style: none;
  }
  .env-item {
    width: 100%;
    min-height: 140px;
    border: 1px solid #ddd;
    padding: 10px;
    cursor: pointer;
    &:hover {
      box-shadow: 0 0 5px rgb(0 0 0 / 30%);
    }
    &__title {
      font-size: 14px;
      font-weight: bold;
      margin: 0 0 20px 0;
      padding: 0;
      line-height: 1;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      &_del {
        margin-left: 10px;
        font-weight: normal;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-shrink: 0;
      }
      .del-icon {
        width: 20px;
        height: 20px;
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    &__desc {
      margin-top: 10px;
      display: flex;
      align-items: flex-start;
      line-height: 20px;
      &_label {
        width: 60px;
        flex-shrink: 0;
        opacity: 0.8;
      }
      &_value {
        flex: 1;
        margin-left: 10px;
        white-space: normal;
        word-break: break-all;
        overflow: hidden;
        max-height: 40px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
</style>
